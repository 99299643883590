<template lang="pug">
	v-btn(@click="$emit('click')" class="dom-element" round fab small :loading="loading")
		v-icon(:color="active ? 'primary' : 'black'") mdi-numeric-1-box-multiple-outline
</template>

<script>
export default {
	name: 'SetFirstButton',
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		active: {
			type: Boolean,
			default: false
		},
	}
}
</script>

<style lang="less" scoped>

</style>

