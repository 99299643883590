<template lang="pug">
	v-card
		v-btn(absolute small fab dark top right @click="close")
			v-icon mdi-close

		v-card-title 
			h3 Elige un panorama		
		v-card-title(class="pt-0")
			v-radio-group(v-model="option" class="options mt-0")
				v-radio(v-for="(option, i) in options" :key="i" :value="option.value" :label="option.label")
		
		v-card-title(class="py-0")
			v-btn(
				:disabled="!option"
				@click="setPOV()"
				class="mx-auto" 
				round 
				small 
			)
				v-icon mdi-target
				span &nbsp; Set POV

		v-card-actions
			v-btn(block @click="save" :disabled="!option" color="primary") Guardar

</template>

<script>
import 'tour-renderer';
import POVSelectionModal from './POVSelectionModal.component';
import Vue from 'vue';

const POVSelectionElementClass = Vue.extend(POVSelectionModal);

export default {
	name: 'LinkOptsOverlay',
	props: {
		options: {
			type: Array,
			default: () => []
		},
		viewerDom: {
			type: HTMLDivElement
		}
	},

	data() {
		return {
			option: null,
			POV: {yaw: 0, pitch: 0}
		}
	},

	methods: {
		save() {
			const link = {...this.option, targetPOV: this.POV};
			this.$emit('save', link);
		},
		close(e) {
			this.$emit('close', e);
		},
		setPOV() {
			this.getPOV(this.option).then((POV) => {
				this.POV = POV;
			});
		},
		getPOV(pano) {
			return new Promise((resolve, reject) => {

				const povSelectionElement = new POVSelectionElementClass({
					propsData: {pano}
				});
				povSelectionElement.$on('save', (POV) => {
					resolve(POV)
					this.viewerDom.removeChild(povSelectionElement.$el);
				});
				povSelectionElement.$on('cancel', () => {
					reject();
					this.viewerDom.removeChild(povSelectionElement.$el);
				});
				povSelectionElement.$mount();
				
				this.viewerDom.appendChild(povSelectionElement.$el);
			});
		}
	}
}
</script>

<style lang="less" scoped>
	.options {
		max-height: 150px;
		overflow: scroll;
		border: 0.5px gray dashed;
		padding: 10px;
	}
</style>

<style>
	html { overflow-y: scroll; }
	
	.overlay-viewer {
		height: 100%;
		width: 100%;
		background-color: black;
		opacity: 0.8;
		position: absolute;
		
		top: 0;
		z-index: 1;
	}
</style>
