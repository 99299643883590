<template lang="pug">
	v-content(class="LinkTour")
		v-container
			h1 Link Tour
			hr(class="mb-4")
			v-layout(row wrap)
				v-flex(md7 offset-md1 xs12 order-md2 class="pano-viewer mb-5" ref="viewer")

				v-flex(xs12 md4)
					v-card(class="pa-3")
						v-select(
							:items="panoramasToSelect"
							v-model="currentPanorama"
							label="Current Panorama"
							@change="updateViewer($event)"
						)

						v-list(two-line)
							v-list-tile
								v-list-tile-action
									v-icon(color="primary") mdi-navigation

								v-list-tile-content
									v-list-tile-title Link
									v-list-tile-sub-title Crea enlaces entre tus panoramas

						v-list
							v-list-tile(v-for="(link, i) in links" :key="i")
								v-list-tile-action
									v-icon(color="green") mdi-link

								v-list-tile-content
									v-list-tile-title {{link.to && link.to.name}}

								v-list-tile-action
									v-btn(icon ripple @click="deleteLink(link)")
										v-icon mdi-delete



</template>

<script>

import TourRenderer from 'tour-renderer';

import {VBtn} from 'vuetify/lib';
import Vue from 'vue';
import LinkOptsOverlay from '@/modules/tours/components/LinkOptsOverlay';
import SetFirstButton from '@/modules/tours/components/SetFirstButton';
import {transformTourDataForViewer} from '@/modules/common/helpers';


export default {
	name: 'LinkTour',
	components: {

	},
	props: {

	},
	data: () => ({
		tour: null,
		tourRef: null,
		setFirstVm: null,
		panoramasToSelect: [],
		links: [],
		currentPanorama: null
	}),

	mounted() {
		this.loadData().then(tour => {
			const data = tour.data();
			data.id = tour.id;
			this.initializeTour(data);
			this.createButtons();
		});
	},

	methods: {
		createButtons() {
			const SetFirstbuttonClass = Vue.extend(SetFirstButton);

			const createLinkbutton = new VBtn();
			const createInfoElementbutton = new VBtn();
			const setFirstbutton = new SetFirstbuttonClass({
				propsData: {tour: this.tour}
			});

			createInfoElementbutton.$slots.default = ['Crear Informacion'];
			createInfoElementbutton.$on('click', this.selectNewInfoElementInViewer.bind(this));
			createInfoElementbutton.$mount();

      createLinkbutton.$slots.default = ['Crear Enlace'];
			createLinkbutton.$on('click', this.selectNewLinkInViewer.bind(this));
			createLinkbutton.$mount();

			setFirstbutton.$on('click', this.setFirst.bind(this));
			setFirstbutton.$mount();

			this.setFirstVm = setFirstbutton;

			const div = document.createElement('DIV');
			div.classList.add('POSITION_BOTTOM_RIGHT');
			div.appendChild(setFirstbutton.$el)
			div.appendChild(createLinkbutton.$el)
			div.appendChild(createInfoElementbutton.$el)

			this.viewer.addDOM(div);
		},

		setFirst() {
			const id = this.viewer.getPano().id;
			const POV = this.viewer.getPOV();

			this.setFirstVm.loading = true;
			this.tourRef.update({
				startPanorama: {POV, id}
			}).then(() => {
				this.setFirstVm.loading = false;
				this.setFirstVm.active = true;
				this.viewer.setFirstPano(this.viewer.getPano());
			});
		},

    selectNewInfoElementInViewer() {
			this.viewer.selectPOVInViewer().then(POV => {
        this.viewer.addInfoElement({POV, isEdit: true, title: 'Titulo', description: 'Descripcion'});
			});
		},


		selectNewLinkInViewer() {
			this.viewer.selectPOVInViewer().then(POV => {
				const LinkOptsOverlayClass = Vue.extend(LinkOptsOverlay);
				const options = this.viewer.panos.array
				.filter(pano => pano.id !== this.currentPanorama)
				.map(pano => {
					return {
						label: pano.name,
						value: pano
					}
				});

				const vm = new LinkOptsOverlayClass({
					propsData: { options, viewerDom: this.viewerDom }
				});

				vm.$mount();
				vm.$on('save', (link) => {
					const newLink = this.viewer.createLinkTo(link.id, POV, link.targetPOV);
					this.viewer.deleteOverlay(overlay);
					this.updateLinks(newLink);
				});
				vm.$on('close', () => {
					this.viewer.deleteOverlay(overlay);
				});

				const overlay = this.viewer.addOverlay(vm.$el, POV);
			});
		},

		updateLinks({to, POV, targetPOV = {pitch: 0, yaw: 0}, id}) {
			const db = this.$getDb();

			const pano = this.tour.panoramas.find(pano => pano.id === this.currentPanorama);

			pano.links.push({toId: to.id, POV, targetPOV, id});
			const tourId = this.$route.params.id;
			return db.collection('tours').doc(tourId).update('panoramas', this.tour.panoramas);
		},

		deleteLink(link) {
			const db = this.$getDb();
			const tourId = this.$route.params.id;

			const pano = this.tour.panoramas.find(pano => pano.id === this.currentPanorama);
			pano.links = pano.links.filter(link2Search => link2Search.id !== link.id)
			this.viewer.deleteLink(link);
			this.links = this.viewer.getPano().links.array;
			return db.collection('tours').doc(tourId).update('panoramas', this.tour.panoramas);
		},

		loadData() {
			const db = this.$getDb();
			const tourId = this.$route.params.id;
			this.tourRef = db.collection('tours').doc(tourId);
			return this.tourRef.get();
		},

		updateViewer(id) {
			this.viewer.setPano(id);
		},

		initializeTour(data) {
			this.tour = data;
			this.setPanoramasSelect();

			this.viewer = new TourRenderer(transformTourDataForViewer(data), this.viewerDom, {
				onLoad: this.onLoadViewer.bind(this),
				autoLoad: true,
				showControls: true,
        editMode: true
			});

      this.viewer.addInfoUpdateListener(this.onInfoElementUpdate.bind(this));
      this.viewer.addInfoDeleteListener(this.onInfoElementDelete.bind(this));
		},

    onInfoElementDelete(info) {
			const db = this.$getDb();

			const dataPano = this.tour.panoramas.find(pano => pano.id === this.currentPanorama);

      dataPano.infoElements.splice(dataPano.infoElements.indexOf(item => item.id === info.id), 1);
			return db.collection('tours').doc(this.tour.id).update('panoramas', this.tour.panoramas);

    },

    onInfoElementUpdate(newInfo) {
			const db = this.$getDb();

			const pano = this.viewer.getPano();
      
      const infoElements = pano.infoElements.array;
      
      const info = infoElements.find(info => info.id === newInfo.id);
      info.title = newInfo.name || newInfo.title;
      info.id = newInfo.id;
      info.POV = newInfo.POV;
      info.description = newInfo.description;

			const dataPano = this.tour.panoramas.find(pano => pano.id === this.currentPanorama);
      dataPano.infoElements = infoElements.map(({POV, id, description, title, name}) => ({POV, title: title || name,name: name || title, description, id}));

			return db.collection('tours').doc(this.tour.id).update('panoramas', this.tour.panoramas);
    },

		onLoadViewer() {
			const pano = this.viewer.getPano();

			this.currentPanorama = pano.id;
			this.links = pano.links.array;
			this.setFirstVm.active = this.viewer.isFirstPano();
		},

		setPanoramasSelect() {
			this.panoramasToSelect = this.tour.panoramas.map(pano => {
				return {value: pano.id, text: pano.name}
			});
		}
	},

	computed: {
		viewerDom() {
			return this.$refs.viewer;
		}
	}
}
</script>

<style lang="less" scoped>
	.pano-viewer {
		height: 400px;
		width: 100%;
	}
</style>

<style>
.POSITION_BOTTOM_RIGHT {
	position: absolute;
	bottom: 0;
	right: 0;
}
</style>


