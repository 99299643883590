<template lang="pug">
	div(class="POVSelectionModal abs full-size")
		div(class="POVSelectionModal__overlay full-size")
		v-layout(align-center justify-center row fill-height)
			v-flex(xs10 sm8 md6 class="height")
				v-card(class="pa-2 full-size")
					div(ref="viewer" class="POVSelectionModal__viewer")
					
					v-card-actions(class="mt-3")
						v-btn(@click="save" class="mx-auto" color="primary") Guardar
						v-btn(@click="$emit('cancel')" class="mx-auto") Cancelar

</template>

<script>
const TourRenderer = window.TourRenderer;

export default {
	name: 'POVSelectionModal',
	data() {
		return {
			viewer: null
		}
	},
	props: {
		pano: {
			type: Object
		}
	},
	computed: {
		viewerDom() {
			return this.$refs.viewer;
		}
	},
	methods: {
		save() {
			const POV = this.viewer.getPOV();
			this.$emit('save', POV);
		}
	},
	mounted() {
		const {url, id} = this.pano;
		this.viewer = new TourRenderer({panos: [{link: url, id}]}, this.viewerDom, {
			autoLoad: true,
			showControls: false,
			keyboardZoom: false,
			mouseZoom: false,
			noTitle: true
		});
	}
}
</script>

<style lang="less" scoped>
	.POVSelectionModal {
		position: absolute;
		top: 0;
		z-index: 1;

		height: 100%;
		width: 100%;
	}

	.POVSelectionModal__overlay {
		background-color: black;
		opacity: 0.8;
		position: absolute;
		top: 0;
	}

	.POVSelectionModal__viewer {
		height: 80%;
		width: 100%;
	}

	// TODO Find better names for this
	.height {
		height: 80%;
	}

	.full-size {
		height: 100%;
		width: 100%;
	}
</style>

